<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page

========================================================================================== -->

<template>
  <div id="knowledge-base-page">
    <!-- JUMBOTRON -->
    <div
      class="rounded-lg w-full"
      style="
        background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
        background-size: cover;
      "
    >
      <div class="knowledge-base-jumbotron-content lg:p-12 sm:p-10 p-8 rounded-lg mb-8" style="border: 1px solid #181d2a; height: 100%">
        <h5 class="font-light text-white">Course Selector</h5>
        <h1 class="mb-2 text-white font-bold" style="font-size: 2.9rem">Select a Course to Practice</h1>
        <vs-input
          v-model="courseSearch"
          placeholder="Search For Course"
          @input="filterCourses"
          class="w-2/5 banner-input"
          icon-pack="feather"
          icon="icon-search"
          icon-no-border
        ></vs-input>
      </div>
    </div>

    <!-- COURSE SELECT CARDS  -->
    <div v-if="coursesLoaded">
      <slide-x-left-transition group class="vx-row match-height" tag="div">
        <div v-for="item in coursesFiltered" :key="item.id" class="vx-col card-width-course mb-base">
          <course-selector-card :item="item" />
        </div>
      </slide-x-left-transition>
      <h1 v-if="courseSearch !== '' && !coursesFiltered.length">No Courses Found</h1>
    </div>
    <div v-if="!coursesLoaded && !coursesNone && coursesFiltered.length === 0" class="simple-spinner">
      <span></span>
    </div>
    <h1 v-if="coursesNone" class="font-bold">You Are Not Subscribed To Any Courses</h1>
  </div>
</template>

<!--suppress ES6ShorthandObjectProperty -->
<script>
import CourseSelectorCard from './components/CourseSelectorCard';

export default {
  data() {
    return {
      courseSearch: '',
      coursesTotal: [],
      coursesFiltered: [],
      coursesLoaded: false,
      coursesNone: false,
    };
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  methods: {
    fetchSubscribedCourses(search = '') {
      this.$http
        .get(`user/@me/subscriptions?search=${search}`)
        .then((subscriptions) => {
          if (subscriptions.data.length === 0) {
            this.coursesNone = true;
            // location.replace('https://www.youtube.com/watch?v=jE2VvGZktJo')
          }
          this.coursesTotal = subscriptions.data;
          this.coursesFiltered = this.coursesTotal;
        })
        .finally(() => {
          this.coursesLoaded = true;
        });
    },
    filterCourses() {
      this.coursesFiltered = this.coursesTotal.filter((sub) => {
        return sub.course.name.toLowerCase().includes(this.courseSearch.toLowerCase().trim());
      });
    },
  },
  beforeDestroy() {
    this.coursesTotal = null;
    this.coursesFiltered = null;
  },
  beforeMount() {
    this.fetchSubscribedCourses();
  },
  components: {
    CourseSelectorCard,
  },
};
</script>
<style lang="scss">
.card-width-course {
  width: 20%;
}

@media (max-width: 2200px) {
  .card-width-course {
    width: 25%;
  }
}

@media (max-width: 1800px) {
  .card-width-course {
    width: 33.333333%;
  }
}

@media (max-width: 1300px) {
  .card-width-course {
    width: 50%;
  }
}

@media (max-width: 750px) {
  .card-width-course {
    width: 100%;
  }
}
</style>
