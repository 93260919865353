<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew

========================================================================================== -->
<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew

========================================================================================== -->

<template>
  <vx-card class="course-selector-card overflow-hidden" :class="item.active ? 'border border-solid border-primary' : ''" v-on="$listeners">
    <div slot="no-body" style="cursor: pointer">
      <img
        :src="
          item.course.thumbnail !== null
            ? item.course.thumbnail
            : 'https://connect-cdn.intellectualpoint.com/assets/images/misc/default-placeholder.png'
        "
        alt="content-img"
        class="responsive card-img-top"
        draggable="false"
        style="width: 100%; height: 270px; object-fit: cover"
      />
    </div>
    <div class="my-2">
      <h5 class="mb-2">{{ item.course.name }}</h5>
    </div>
    <div class="my-4">
      <div class="flex justify-between">
        <small class="font-semibold">Not Started</small>
        <small class="font-semibold">Completed</small>
      </div>
      <vs-progress :percent="progress" class="block mt-1 shadow-md" color="success"></vs-progress>
    </div>
    <div class="vx-row">
      <!--<div class="vx-col w-1/2">
              <vs-button class="mt-4 mr-2 shadow-lg font-semibold w-full  bg-danger-gradient "
                        icon="icon-info" icon-pack="feather" color="danger"
                        type="gradient" @click="$router.push({name: 'courses-course-view', params: {courseId: item.id }})">
               Course Progress
             </vs-button>
           </div>-->
      <div class="vx-col w-full">
        <vs-button
          class="mt-4 mr-2 shadow-lg font-semibold w-full"
          :icon="item.active ? 'icon-play' : 'icon-check-circle'"
          icon-pack="feather"
          :color="item.active ? 'primary' : 'success'"
          type="border"
          @click="updateActiveCourse(item.course.id, item.active)"
          >{{ item.active ? 'Continue Practicing' : 'Switch To Course' }}
        </vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  methods: {
    updateActiveCourse(id, active = false) {
      if (active) {
        return this.$router.push('/apps');
      }

      const pl = {
        courseId: id,
      };

      this.$http
        .patch('user/@me/activeCourse', pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.notify({
              color: 'success',
              title: 'Course Changed',
              text: 'You have successfully changed your active course',
              position: 'bottom-right',
            });

            this.$router.push('/apps');
          }
        })
        .catch((exception) => {
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to update courses',
            text: exception.response.data.message,
            position: 'top-right',
          });
        });

      this.$emit('course-changed');
    },
  },
  created() {
    /*this.$http.get(`progress/user/specific/@me/course/progress?id=${this.item.id}`)
      .then(response => {
        this.progress = response.data.data.completion_percent
      }) */
  },
};
</script>

<style lang="scss" scoped>
.course-selector-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
